import type { OrderProductOptions } from "../OrderProductOptions.js";

export const emptyOptions: OrderProductOptions = {
	numbers: [],
	extraFruit: false,
	glutenFree: false,
	lactoseFree: false,
	meringues: false,
	creamColor: undefined,
	labels: [],
	lyingPhoto: undefined,
	standingPhotos: [],
	macaroons: undefined,
	figurines: [],
};
